import React from 'react';
import logo from './logo final.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>3dCat.pl</p>
        <img src={logo} className="App-logo" alt="logo" />
        <p>Strona w przygotowaniu</p>
        <a
          className="App-link"
          href="https://www.instagram.com/3dcat.pl/"
        >
          Przejdź do instagrama
        </a>
      </header>
    </div>
  );
}

export default App;
